import React from 'react'
import { Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { LegalNoticeTable } from './LegalNoticeTable'

export const LegalNotice = () => <LegalNoticeTable />

LegalNotice.NavItem = () => (
  <Nav.Link
    as={NavLink}
    to='/legal/scta'
  >特定商取引法に基づく表記
  </Nav.Link>
)
