import React from 'react'
import {
  Table
} from 'react-bootstrap'

export const LegalNoticeTable = () => (
  <Table bordered responsive>
    <tbody>
      <tr><td>運営団体</td><td>パーソルワークスデザイン株式会社</td></tr>
      <tr><td>代表者</td><td>軽井 宏直</td></tr>
      <tr><td>URL</td><td><a href='https://sub2.persol-wd.co.jp/icdl/' target='_blank' rel='noreferrer'>https://sub2.persol-wd.co.jp/icdl/</a></td></tr>
      <tr><td>所在地</td><td>東京都豊島区池袋2-65-18 池袋WESTビル</td></tr>
      <tr><td>対象サービス・商品</td><td>試験運営・学習プログラム・教材の提供</td></tr>
      <tr><td>受験料</td><td>12,000円（税込）<br />不合格の場合1回のみ再受験料は7,700円（税込）</td></tr>
      <tr>
        <td>検定料以外の必要料金</td>
        <td>
          <ul>
            <li>入金にかかる各種手数料</li>
            <li>Web申込、学習にかかる通信料</li>
          </ul>
        </td>
      </tr>
      <tr><td>お支払い方法</td><td>クレジットカード決済</td></tr>
      <tr><td>支払期限</td><td>各クレジットカード会社の会員規約に基づく。</td></tr>
      <tr><td>お問い合わせ先</td>
        <td>
          <a href='mailto:pwd-icdl-support@persol.co.jp'>pwd-icdl-support@persol.co.jp</a>
          <p>電話によるお問い合わせは承っておりませんが、電話番号のご確認が必要な場合は上記メールアドレスよりお問い合わせいただければ、遅滞なく提示します。</p>
        </td>
      </tr>
      <tr><td>役務提供時期</td><td>決済完了日から7営業日以降に学習・受験用システムのIDおよびパスワードを登録メールアドレスに通知</td></tr>
      <tr><td>キャンセル・変更について</td><td>受験者本人都合によるお申込み後の試験日時・会場・モジュールの変更およびキャンセル（返金を含む）はお受けできません。あらかじめご了承ください。</td></tr>
    </tbody>
  </Table>
)
