import React from 'react'
import {
  Nav
} from 'react-bootstrap'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from 'react-router-dom'
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { Home } from './features/home'
import {
  Layout,
  NotFound
} from './features/common'
import { AuthNavItem } from './features/auth'
import {
  Profile,
  ProfileInput
} from './features/profile'
import {
  EmailSetting,
  PasswordSetting,
  Setting
} from './features/setting'
import {
  ApplicationList,
  ExamOrCourse,
  ExamOrCourseList,
  ExamOrCourseNameDetail
} from './features/examOrCourse'
import {
  Admin,
  ExamOrCourseNameList as AdminExamOrCourseNameList,
  ExamOrCourseList as AdminExamOrCourseList,
  ApplicationList as AdminApplicationList,
  VenueExecutionList as AdminVenueExecutionList,
  OperationOrganizationList as AdminOperationOrganizationList,
  DigitalCertificateList as AdminDigitalCertificateList,
  DigitalCertificateForm as AdminDigitalCertificateForm,
  CategoryList as AdminCategoryList,
  TagList as AdminTagList
} from './features/admin'
import {
  Legal,
  LegalNotice,
  TermsOfUse
} from './features/legal'

library.add(fas, far)
dom.watch()

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route
        path='/'
        element={
          <Layout>
            <Nav className='small'>
              <ExamOrCourse.NavItem />
              <LegalNotice.NavItem />
              <Admin.NavItem />
              <Nav.Link
                href='https://288d45cd.form.kintoneapp.com/public/2f6850352a6d4c12337f76c4e366361a039b4c5b8ec72c31c21ec7be6b443368'
                target='_blank'
              >問合せフォーム
              </Nav.Link>
            </Nav>
            <Nav className='ms-auto'>
              <AuthNavItem>
                <ApplicationList.NavItem />
                <Profile.NavItem />
                <Setting.NavItem />
              </AuthNavItem>
            </Nav>
          </Layout>
             }
      >
        <Route index element={<Home />} />
        <Route path='examOrCourse' element={<ExamOrCourse />}>
          <Route index element={<Navigate to='browse' />} />
          <Route path='browse' element={<ExamOrCourseList />} />
          <Route path='browse/:examOrCourseNameId' element={<ExamOrCourseNameDetail />} />
          <Route path='application' element={<ApplicationList />} />
        </Route>
        <Route path='profile' element={<Profile />}>
          <Route index element={<Navigate to='input' />} />
          <Route path='input' element={<ProfileInput />} />
        </Route>
        <Route path='setting' element={<Setting />}>
          <Route index element={<Navigate to='email' />} />
          <Route path='email' element={<EmailSetting />} />
          <Route path='password' element={<PasswordSetting />} />
        </Route>
        <Route path='admin' element={<Admin />}>
          <Route index element={<Navigate to='examOrCourse' />} />
          <Route path='examOrCourseName' element={<AdminExamOrCourseNameList />} />
          <Route path='examOrCourse' element={<AdminExamOrCourseList />} />
          <Route path='application' element={<AdminApplicationList />} />
          <Route path='venueExecution' element={<AdminVenueExecutionList />} />
          <Route path='operationOrganization' element={<AdminOperationOrganizationList />} />
          <Route path='digitalCertificateList' element={<AdminDigitalCertificateList />} />
          <Route path='digitalCertificate' element={<AdminDigitalCertificateForm />} />
          <Route path='category' element={<AdminCategoryList />} />
          <Route path='tag' element={<AdminTagList />} />
        </Route>
        <Route path='legal' element={<Legal />}>
          <Route path='scta' element={<LegalNotice />} />
          <Route path='terms' element={<TermsOfUse />} />
          <Route path='*' element={<NotFound />} />
        </Route>
        <Route path='*' element={<NotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>
)

export default App
